import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  // Mensajes de depuración para verificar los valores de las variables de entorno
  console.log('REACT_APP_GRAFANA_URL:', process.env.REACT_APP_GRAFANA_URL);
  console.log('REACT_APP_GRAFANA_API_TOKEN:', process.env.REACT_APP_GRAFANA_API_TOKEN);

  const [data, setData] = useState(null);

  useEffect(() => {
    // Define la URL del endpoint de Grafana
    const url = `https://grafana.swiftlet.xyz/api/dashboards/uid/e09ac252-792b-475d-abdc-5e5b8f85e587`;

    // Realiza la solicitud a la API de Grafana
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer glsa_yT0Zin2zM413pnnL0iI9AcVLadzjJxH6_139b70b3`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data from Grafana API:', error);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h2>Grafana - Swiftlet</h2>
        <iframe src="https://grafana.swiftlet.xyz/d-solo/e09ac252-792b-475d-abdc-5e5b8f85e587/swiftlet?orgId=1&from=1723014817069&to=1723619617069&panelId=1" width="450" height="200" frameborder="0"></iframe>
        {/* Mostrar datos si están disponibles */}
        {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
      </header>
    </div>
  );
}

export default App;
